import { Box, Grid, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDayCloseMutation } from 'src/redux/services/auditApi';
import { resetState } from 'src/redux/services/authSlice';
import { useGetDateQuery } from 'src/redux/services/dashboardApi';
import { RootState } from 'src/redux/store';
import { useConfirmation } from '../../../../components/Dialog/ConfirmationService';
import notification from '../../../../components/Notification';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const gdata = useSelector((state: RootState) => state.gdata);
  const { data: auditdt } = useGetDateQuery(gdata.company);
  const [dayClose] = useDayCloseMutation();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const isAdmin = gdata.menu?.find((v) => v.mn_link === '/admin/company');
  const modFO = gdata.menu?.find((v) => v.mn_module == 1);
  const modPOS = gdata.menu?.find((v) => v.mn_module == 3);
  const modBANQ = gdata.menu?.find((v) => v.mn_module == 4);

  useEffect(() => {
    if (!gdata || !auditdt) return;

    if (
      moment(gdata?.today)
        .utcOffset('+05:30')
        .diff(moment(auditdt?.dt), 'days') !== 0
    ) {
      dispatch(resetState());

      navigate('/');
    }
  }, [auditdt, gdata]);

  const headMenuFO =
    !!gdata.menu?.find(
      (v) =>
        v.mn_link ===
        '/transaction/focheckin'.substring(0, v.mn_link.length + 1)
    )?.um_view || gdata.username === 'Demo';
  const headMenuPOS =
    !!gdata.menu?.find(
      (v) =>
        v.mn_link ===
        '/transaction/potablelist'.substring(0, v.mn_link.length + 1)
    )?.um_view || gdata.username === 'Demo';
  const headMenuBQ =
    !!gdata.menu?.find(
      (v) =>
        v.mn_link === '/transaction/bqbill'.substring(0, v.mn_link.length + 1)
    )?.um_view || gdata.username === 'Demo';

  useEffect(() => {
    if (!gdata.menu || !gdata) return;
    if (
      moment(gdata?.today)
        .utcOffset('+05:30')
        .diff(moment().format('YYYY-MM-DD'), 'days') < 0
    ) {
      if (modFO) {
        notification.success({
          title: 'Error',
          message: 'Please Click Save To Day Close'
        });
        navigate('/audit');
      } else {
        confirm({
          variant: 'danger',
          catchOnCancel: true,
          title: 'BlueHMS',
          description: 'Do you want to Close YesterDay?'
        })
          .then(() => {
            dayClose(gdata.company)
              .then(() => {
                notification.success({
                  title: 'Sucess',
                  message: 'Day Closed Sucessfully'
                });

                dispatch(resetState());

                navigate('/');
              })
              .catch(() => {
                notification.error({
                  title: 'Error',
                  message: 'Error'
                });
              });
          })
          .catch(() => {
            console.log('error');
          });
      }
    }
  }, [gdata.menu]);

  useEffect(() => {
    if (location.pathname != '/' || isAdmin) return;

    if (modFO && headMenuFO) navigate('/status/status');
    else if (modPOS && headMenuPOS) navigate('/transaction/potablelist');
    else if (modBANQ && headMenuBQ) navigate('/transaction/bqbooking');
  }, []);

  // Your styles for background, borderRadius, and padding
  const listItemStyle = {
    background: '#ffffff', // Replace with your preferred background color
    borderRadius: '10px', // Replace with your preferred border radius
    padding: '1px' // Replace with your preferred padding
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            component={NavLink}
            to={modFO && headMenuFO ? '/audit' : '/'}
          >
            <ListItemText
              primaryTypographyProps={{
                noWrap: true,
                color:
                  moment(gdata?.today)
                    .utcOffset('+05:30')
                    .diff(moment(), 'days') < 0
                    ? 'error'
                    : 'green',
                fontWeight: 'bold'
              }}
              primary={moment(gdata?.today)
                .utcOffset('+05:30')
                .format('DD-MMM-YY')}
              secondaryTypographyProps={{
                fontWeight: 'bold',
                textAlign: 'center'
              }}
              secondary={
                <Grid
                  color="#0078d4"
                  style={{
                    ...listItemStyle,
                    fontWeight: 'bold'
                  }}
                >
                  {moment(currentTime).utcOffset('+05:30').format('HH:mm:ss')}
                </Grid>
              }
            />
          </ListItem>
          {modFO && headMenuFO ? (
            <>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/status/status"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Status"
                />
              </ListItem>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/status/availabilty"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Availability"
                />
              </ListItem>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/status/search"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Search"
                />
              </ListItem>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/status/inhouse"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="In-House"
                />
              </ListItem>
            </>
          ) : null}
          {modPOS && headMenuPOS ? (
            <>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/transaction/potablelist"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Table List"
                />
              </ListItem>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/transaction/posettle"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Settlement"
                />
              </ListItem>
            </>
          ) : null}
          {modBANQ && headMenuBQ ? (
            <>
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/status/bqsearch"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Hall Bills"
                />
              </ListItem>
            </>
          ) : null}
        </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;
