import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  alpha,
  Box,
  Button,
  Divider,
  IconButton,
  lighten,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { logoPath, Print } from 'src/Common/common';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  useGetFOEGRCsQuery,
  useInsertFOECGRCMutation
} from 'src/redux/services/foecheckinApi';
import { RootState } from 'src/redux/store';
import { useConfirmation } from '../../../components/Dialog/ConfirmationService';
import notification from '../../../components/Notification';
import HeaderMenu from './Menu';
import HeaderUserbox from './Userbox';
import { useNavigate } from 'react-router';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const gdata = useSelector((state: RootState) => state.gdata);
  const glogic = useSelector((state: RootState) => state.glogic.glogic);
  const [InsertFOECGRC] = useInsertFOECGRCMutation();
  const { data, refetch } = useGetFOEGRCsQuery(gdata.company);

  const modFO = gdata.menu?.find((v) => v.mn_module == 1);
  const modPOS = gdata.menu?.find((v) => v.mn_module == 3);
  const modBANQ = gdata.menu?.find((v) => v.mn_module == 4);

  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  const handlePrintToken = () => {
    let html =
      '<style>' +
      'body {' +
      'font-size: 14px;' +
      'font-family: Calibri;' +
      'padding: 15px;' +
      '}' +
      'table {' +
      'font-size: 14px;' +
      'font-family: Calibri;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid black;' +
      'border-collapse: collapse;' +
      'padding: 2px;' +
      '}' +
      '</style>' +
      "<table style='width:100%'>" +
      '<tr>' +
      "<td align='center' colspan=2><h3>" +
      gdata.companyname +
      '</h3></td>' +
      '</tr>' +
      "<tr><td style='border: 1px black;' colspan=2>Food Prepared on</td></tr>" +
      '<tr><td>Date</td>' +
      '<td><h4>' +
      moment().format('DD-MM-YY') +
      '</h4></td></tr>' +
      '</h4><tr><td>Time</td>' +
      '<td><h4>' +
      moment().format('hh:mm A') +
      '</h4></td></tr>' +
      '</td></tr>' +
      '<tr><td colspan=2>***Consume Within 2 Hours</td></tr>' +
      "<tr><td colspan=2>***Don't Keep Containers Fully Closed</td></tr>" +
      "<tr><td style='border-top: 1px dashed black; border-bottom: 1px dashed black;' align='center' colspan=2>Thank You</td></tr>" +
      '</table>' +
      '<br/>' +
      '<br/>' +
      '<br/>' +
      '<br/>';

    if (glogic.billprinter) {
      Print(glogic.billprinter, html);
    } else {
      var myWindow = window.open('', 'Token');
      myWindow.document.write('<html><head><title>KOT</title>');
      myWindow.document.write(
        "<style type='text/css'> *, html {margin:0;padding:0;} </style>"
      );
      myWindow.document.write('</head><body>');
      myWindow.document.write(html);
      myWindow.document.write('</body></html>');
      myWindow.document.close(); // necessary for IE >= 10
      let myDelay = setInterval(checkReadyState, 100);

      function checkReadyState() {
        if (myWindow.document.readyState === 'complete') {
          clearInterval(myDelay);
          myWindow.focus(); // necessary for IE >= 10

          myWindow.print();
          myWindow.close();
        }
      }
    }
  };

  const handlePrintGRC = async () => {
    await refetch();
    if (data.length > 0) {
      confirm({
        variant: 'danger',
        catchOnCancel: true,
        title: 'BlueHMS',
        description: 'GRC Already Exisits Do you want to Print Last GRC?'
      })
        .then(() => {
          PrintGRC(data[data.length - 1]);
          return;
        })
        .catch(() => {
          InsertFOECGRC({ company: gdata.company })
            .then((res) => {
              const dat: any = res;

              PrintGRC(dat.data);
            })
            .catch(() => {
              notification.error({
                title: 'Error',
                message: 'Error'
              });
            });
          return;
        });
    } else {
      InsertFOECGRC({ company: gdata.company })
        .then((res) => {
          const dat: any = res;

          PrintGRC(dat.data);
        })
        .catch(() => {
          notification.error({
            title: 'Error',
            message: 'Error'
          });
        });
    }
  };

  const PrintGRC = (dat) => {
    let html =
      '<style>' +
      'body {' +
      'font-size: 14px;' +
      'font-family: Calibri;' +
      'padding: 15px;' +
      '}' +
      'table {' +
      'font-size: 14px;' +
      'font-family: Calibri;' +
      '}' +
      'table, th, td {' +
      'border-radius: 10px;' +
      'border: 1px solid black;' +
      'border-collapse: collapse;' +
      'padding: 2px;' +
      '}' +
      '</style>' +
      "<table style='width:100%'>" +
      '<tr>' +
      `<td padding=5 height='50' align='left' colspan=8><div style="width:50%; display:inline-block;" align="left"><img src='${
        logoPath + gdata.company + gdata.logo
      }' alt='new' style="width:auto;height:100;" /></div>` +
      '<div align="right" style="width:50%; display:inline-block;">' +
      '<h1>GUEST REGISTRATION CARD</h1><h3>' +
      gdata.companyname +
      '</h3>Date : ' +
      moment().format('DD-MM-YY') +
      `<br />GRC # :${dat.gr_no}</div></td>` +
      '</tr>' +
      '<tr><td style="width:15%;">Guest Name</td><td style="width:50%;" colspan=4></td><td style="width:15%;">Vehicle No</td><td colspan="2" style="width:20%;"></td></tr>' +
      '<tr><td>Company</td><td colspan=4></td><td>GSTIN</td><td colspan="2"></td></tr>' +
      '<tr><td style="height:100px; vertical-align:top;">Address</td><td colspan=7></td></tr>' +
      '<tr><td>Contact No</td><td colspan=4></td><td>DOB</td><td colspan="2"></td></tr>' +
      '<tr><td>Email Address</td><td colspan=7></td></tr>' +
      '<tr><td>Purpose of Visit</td><td colspan=7> Official / Business / Tourist / Temple Visit / Personal</td></tr>' +
      '<tr><td>ID Proof</td><td colspan=7> Driving Licence / Passport / Aadhar Card / Voter ID / Corporate ID</td></tr>' +
      '<tr><td>Arriving From</td><td colspan=2 style="width:25%;"></td><td style="width:15%;">Proceeding To</td><td colspan=4></td></tr>' +
      '<tr><td>Payment Mode</td><td colspan=7> Cash / Card / Company (If Company Specify)</td></tr>' +
      '<tr><td colspan=8></td></tr>' +
      '<tr><td colspan=8 align="center"><h4>FOR FOREIGNERS</h4></td></tr>' +
      '<tr><td>Passport no</td><td colspan=2 style="width:25%;"></td><td style="width:15%;">Issue Date</td><td></td><td>Expire Date</td><td colspan=2></td></tr>' +
      '<tr><td>Visa no</td><td colspan=2 style="width:25%;"></td><td style="width:15%;">Issue Date</td><td></td><td>Expire Date</td><td colspan=2></td></tr>' +
      '<tr><td>Arrival in India</td><td colspan=2 style="width:25%;"></td><td style="width:15%;" colspan="2">Proposed Stay in India</td><td colspan="3"></td></tr>' +
      '<tr><td colspan=8></td></tr>' +
      `<tr><td>Arrival Date</td><td>${moment(dat?.gr_date).format(
        'DD-MM-YY'
      )}</td><td style="width:15%;">Time</td><td>${moment(dat?.gr_date).format(
        'HH:mm'
      )}</td><td>Departure Date</td><td></td><td style="width:15%;">Time</td><td></td></tr>` +
      '<tr><td>No of Adults</td><td></td><td style="width:15%;">Child</td><td></td><td>No of Ext.Bed</td><td></td><td style="width:15%;">B.#</td><td></td></tr>' +
      '<tr><td colspan=8><h4>Terms and conditions: </h4></td></tr>' +
      '<tr><td colspan=8><ol padding="2">' +
      '<li>Check-in Check-out time is 24 Hrs</li>' +
      '<li>The guest acknowledge joint and several liability for all services rendered until full settlement of bills</li>' +
      '<li>Guests will be held responsible for any loss or damage to  caused by themselves, their friends or any person for whom they are responsible.</li>' +
      '<li>Hotel Management  is not responsible for your personal belongings and valuables like  money, jewellery or any other valuables left by guests in the rooms.</li>' +
      '<li>Regardless of charge instructions, I acknowledge that I am personally liable for the payment of all charges incurred by me during my stay at </li>' +
      '<li>I/We have read & accepted the rules, tems & conditions which are printed on Registration Card</li>' +
      '</ol></td></tr>' +
      '<tr><td style="height:30px; vertical-align:bottom;" colspan=8>Guest Signature</td></tr>' +
      '<tr><td colspan=8 align="center"><h4>FOR FRONT OFFICE USE</h4></td></tr>' +
      '<tr><td>Room No</td><td></td><td>Type</td><td></td><td>Rate</td><td></td><td>Plan</td><td"></td></tr>' +
      '<tr><td>Advance</td><td></td><td>Mode</td><td colspan=3>Cash / Card / Transfer</td><td>Rcpt #</td><td></td></tr>' +
      '<tr><td>Booked By</td><td colspan=7>Direct / Reservation / Online / EMail / Phone</td></tr>' +
      '<tr><td colspan=8>INSTRUCTIONS</td></tr>' +
      '<tr><td colspan=8>Reference :</td></tr>' +
      '<tr><td colspan=4 style="height:30px; vertical-align:bottom;">Manager Signature</td>' +
      '<td colspan=4 style="height:100px; vertical-align:bottom;" align="right">Receptionist Signature</td></tr>' +
      '</table>';
    var myWindow = window.open('', 'GRC');
    myWindow.document.write('<html><head><title>GRC</title>');
    myWindow.document.write(
      "<style type='text/css'> *, html {margin:0;padding:0;} </style>"
    );
    myWindow.document.write('</head><body>');
    myWindow.document.write(html);
    myWindow.document.write('</body></html>');
    myWindow.document.close(); // necessary for IE >= 10
    let myDelay = setInterval(checkReadyState, 100);

    function checkReadyState() {
      if (myWindow.document.readyState === 'complete') {
        clearInterval(myDelay);
        myWindow.focus(); // necessary for IE >= 10

        myWindow.print();
        myWindow.close();
      }
    }
  };

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Typography
        variant="h4"
        color={'#2aa752'}
        paddingTop={1}
        onClick={() => {
          navigate(`/status/status`);
        }}
      >
        {gdata.companyname}
      </Typography>
      <Typography variant="h4" color={'#0078d4'} paddingTop={1}>
        {gdata.city}
      </Typography>
      <HeaderMenu />
      {modFO ? <Button onClick={handlePrintGRC}>Print GRC</Button> : null}
      {modPOS ? <Button onClick={handlePrintToken}>Print Token</Button> : null}
      <HeaderUserbox />
    </HeaderWrapper>
  );
}

export default Header;
