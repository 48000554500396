import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import Fuse from 'fuse.js';

function BTComboBox(props: any) {
  const { onValChange, onKeyDown } = props;
  const { field, fieldState } = useController(props);
  const { register } = useForm();
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(props.data || []);

  useEffect(() => {
    setFilteredOptions(props.data || []);
  }, [props]);

  useEffect(() => {
    register(field.name);
  }, [register, field.name]);

  const fuse = new Fuse(props.data || [], {
    includeScore: true,
    keys: ['label'] // Adjust keys according to your data structure
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !isDropdownOpen) {
      event.preventDefault();

      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusables: NodeListOf<HTMLElement> =
        document.querySelectorAll(focusableElements);

      const visibleFocusables = Array.from(focusables).filter(
        (el) =>
          el.offsetWidth > 0 &&
          el.offsetHeight > 0 &&
          !el.closest('.MuiAutocomplete-popper')
      );

      const currentIndex = visibleFocusables.indexOf(
        document.activeElement as HTMLElement
      );
      const nextIndex = currentIndex + 1;

      if (nextIndex >= 0 && nextIndex < visibleFocusables.length) {
        visibleFocusables[nextIndex].focus();
      }
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  // Handle input change to update the search and filter options
  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue || '');

    if (newInputValue && newInputValue.length > 0) {
      const searchResult = fuse.search(newInputValue);
      setFilteredOptions(searchResult.map((result) => result.item)); // Update filtered options based on Fuse.js search
    } else {
      setFilteredOptions(props.data || []); // Reset options to full list if input is empty
    }
  };

  return (
    <Autocomplete
      id={props.name}
      options={filteredOptions} // Use filtered options for autocomplete
      filterOptions={(x) => x} // Disable default filtering
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
      ref={field.ref}
      onChange={(e, newValue) => {
        const newValueId = props.multiple
          ? newValue
            ? newValue?.map((item: any) => item.id)
            : []
          : newValue
          ? newValue.id
          : '';

        field.onChange(newValueId);

        if (onValChange) onValChange(newValue);
      }}
      value={
        props.multiple
          ? field.value
            ? props.data?.filter((option: any) =>
                field.value.includes(option.id)
              )
            : []
          : props.data?.find((option: any) => option.id === field.value) || null
      }
      onBlur={field.onBlur}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
      multiple={props.multiple ? props.multiple : false}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={props.label}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
          autoFocus={props.autoFocus}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      )}
      style={{ width: '100%', color: 'black' }}
      disabled={props.disabled}
    />
  );
}

export default BTComboBox;
